import React from 'react'

type Props = {
    children?: any;
}

function Tabs({
    children
}: Props) {
    const [activeTab, setActiveTab] = React.useState(0)
    return (
        <>
            {/* <div
                className='overflow-x-auto'
            >
                <div className='flex'
                    style={{
                        borderBottom: "1px solid #f1f1f1"
                    }}
                >
                    {React.Children.map(children, (child, index) => {
                        const { props } = child;
                        const { title } = props || {};
                        return (
                            <div key={index}
                                className={`py-4 
                            flex-1
                            flex-shrink-0
                            whitespace-nowrap
                            px-8 
                            bg-gray-100
                            hover:bg-gray-200
                            active:bg-gray-300
                            ${activeTab === index ? 'bg-gray-300 font-bold' : ''}
                            cursor-pointer`
                                }
                                style={{
                                    borderRight: "1px solid #f1f1f1"
                                }}
                                onClick={() => setActiveTab(index)}
                            >
                                {title}
                            </div>
                        )
                    })}
                </div>
            </div> */}
            <div className='flex flex-col my-4 px-4 text-justify'>
                {React.Children.map(children, (child, index) => {
                    const { props } = child;
                    const { title } = props || {};
                    return (
                        <div className='my-4'>
                            <h2>{title}</h2>
                            {child}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Tabs